import React from "react";
import { FaAppStoreIos, FaGithub } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";

interface IProject {
  title: string;
  role: string;
  images: string[];
  content: string;
  techStack: string;
  liveSite: string;
  github?: string | null;
  subtitle: string;
  appLinks?: string[];
  prizes?: string[];
}
/**
 * Reusable Project display
 * @param {string} props -
 */
const Project = ({
  title,
  role,
  images,
  content,
  techStack,
  liveSite,
  github,
  subtitle,
  appLinks,
  prizes,
}: IProject) => {

  return (
    <div className="outerProjectContainer">
      <div className="projectContainer">
        <div className="projectHeader">
          <h3>{title}</h3>
          <h4 className="projectRole">{role}</h4>
        </div>
        <div className="centerRow">
          <div className="projectImage">
            <img
              src={`${process.env.PUBLIC_URL}/img/projects/${images[0]}`}
              alt={title}
            />
          </div>

          <div className="projectContent">
            <h4>{subtitle}</h4>
            <p>{content}</p>

            <h4>Tech Stack</h4>
            <p>{techStack}</p>

            {prizes && (
              <div className="projectPrizes">
                <h4>Prizes & Awards</h4>
                <ul>
                  {prizes.map((prize, index) => {
                    return <li key={`prize${index}`}>{prize}</li>;
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="centerRow appIconRow">
          {liveSite !== null ? (
            <a href={liveSite} target="__blank" rel="noreferrer">
              <button className="redButton">Visit</button>
            </a>
          ) : null}
          {github && github !== null ? (
            <a href={github} target="__blank" rel="noreferrer">
              <button className="redButton">
                <FaGithub />
              </button>
            </a>
          ) : null}
          {appLinks && (
            <>
              <a
                href={appLinks[0]}
                target="__blank"
                rel="noreferrer"
                className="iconButton"
              >
                <FaAppStoreIos />
              </a>
              <a
                href={appLinks[1]}
                target="__blank"
                rel="noreferrer"
                className="iconButton"
              >
                <IoLogoGooglePlaystore />
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Project;
