import React from "react";
import { FaEnvelope, FaGithub, FaLinkedin } from "react-icons/fa";
import InterestingThings from "./InterestingThings";
const About = () => {
  return (
    <div id="aboutMe">
      <h2>About Me</h2>
      <div className="aboutRow">
        <div className="aboutMeImage">
          <img src="../../img/Josie_Daw.jpg" alt="Josie Daw" />
        </div>
        <div className="aboutMeText">
          <p>
            Hey there! I'm a British developer who's currently embracing the
            vibrant tech scene in South Korea. I thrive on the excitement of
            working on a diverse range of personal and professional projects
            that keep me on my toes. Lately, I've been diving into NextJS,
            Tailwind CSS, and Firebase to rapidly create and deploy sites for
            worthwhile causes.
          </p>
          <p>
            With an academic background (and a couple degrees) in History, my
            greatest passion lies in the pursuit of knowledge and helping others
            unlock their potential. When I'm not immersed in code, you can often
            find me teaching free coding workshops, mentoring individuals, or
            hosting tech events to help more people from different backgrounds
            get into tech through my group{" "}
            <a
              target="__blank"
              rel="noreferrer"
              href="https://constantcoding.com"
            >
              Constant Coding
            </a>
            . In November 2023, I organized the first ever <a
              target="__blank"
              rel="noreferrer"
              href="https://www.seoultechimpact.com"
            >
              Seoul Tech Impact
            </a> hackathon for social good in South Korea.
          </p>
          <p>
            In my downtime, I channel my creativity into bringing unique ideas
            to life. Whether it's crafting a solo project or collaborating with
            talented individuals, I love the challenge of turning innovative
            concepts into reality.
          </p>
          <p>
            Feel free to connect with me on LinkedIn to learn more about my
            unusual journey into tech, or simply scroll down to delve into my
            recent projects. I'm always open to new opportunities and exciting
            challenges!
          </p>
          <div className="centerRow appIconRow">
            <a
              href="https://www.linkedin.com/in/josiedaw/"
              className="iconButton"
              target="__blank"
              rel="noreferrer"
            >
              <FaLinkedin />
            </a>
            <a href="mailto:josmdaw@gmail.com" className="iconButton">
              <FaEnvelope />
            </a>
            <a
              href="https://github.com/JosDaw"
              target="__blank"
              rel="noreferrer"
              className="iconButton"
            >
              <FaGithub />
            </a>
          </div>
          <InterestingThings />
        </div>
      </div>
    </div>
  );
};

export default About;
